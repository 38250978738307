import React, { FormEventHandler, useState } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form';
import styled from 'styled-components';

import { Box, Button, Divider, Modal, Typography } from '@hero-design/react';

import useLocation from '@shared/hooks/useLocation';
import useWindowSize from '@shared/hooks/useWindowSize';

import JobSearchForm from './JobSearchForm';

const FormWrapper = styled.form<{ isSmallScreen: boolean }>`
  width: fill-available;
  width: -moz-available;
  width: -webkit-fill-available;
  max-width: ${({ isSmallScreen }) => (isSmallScreen ? undefined : '500px')};
`;

interface TSearchFormProps {
  query: string;
  vendorLocationId?: number;
  vendorLocationName?: string;
  countryCode?: string;
}

type TJobSearchProps = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  control: Control<TSearchFormProps>;
  setValue: UseFormSetValue<TSearchFormProps>;
  getValues: UseFormGetValues<TSearchFormProps>;
  reset: UseFormReset<TSearchFormProps>;
};

const JobSearch = ({
  onSubmit,
  control,
  setValue,
  getValues,
  reset,
}: TJobSearchProps) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const { isSmallScreen } = useWindowSize();
  const location = useLocation({ control, setValue });
  const { query, vendorLocationName } = getValues();

  return (
    <FormWrapper isSmallScreen={isSmallScreen} onSubmit={onSubmit}>
      {isSmallScreen ? (
        <>
          <Box
            borderRadius="large"
            bgColor="default-background"
            p="medium"
            style={{ display: 'flex' }}
          >
            <Box
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Typography.Text sx={{ alignSelf: 'center' }}>
                {query || 'Any job'}
              </Typography.Text>
              <Divider variant="vertical" marginY="medium" marginX="medium" />
              <Typography.Text sx={{ alignSelf: 'center' }}>
                {vendorLocationName || location.countryName || 'Anywhere'}
              </Typography.Text>
            </Box>
            <Box
              borderRadius="large"
              borderColor="black"
              borderWidth="base"
              borderStyle="solid"
              mr="small"
            >
              <Button.Icon
                data-test-id="filter-button"
                icon="adjustment"
                sx={{ p: 'small' }}
                onClick={() => setFilterOpen(true)}
              />
            </Box>
            <Box
              borderRadius="large"
              borderColor="black"
              borderWidth="base"
              borderStyle="solid"
            >
              <Button.Icon
                data-test-id="search-button"
                icon="search-outlined"
                sx={{ p: 'small' }}
                type="submit"
              />
            </Box>
          </Box>
          {filterOpen && (
            <Box
              bgColor="default-light-background"
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                zIndex: '99999',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              }}
            >
              <Modal.Header>
                Filters
                <Modal.CloseButton onClick={() => setFilterOpen(false)} />
              </Modal.Header>

              <Divider marginY="xsmall" />
              <Box p="medium" style={{ flex: 1, overflow: 'scroll' }}>
                <JobSearchForm
                  location={location}
                  control={control}
                  setValue={setValue}
                />
              </Box>
              <Divider marginY="xsmall" />
              <Modal.Footer>
                <Button text="Reset" onClick={() => reset()} variant="text" />
                <Button text="Save" onClick={() => setFilterOpen(false)} />
              </Modal.Footer>
            </Box>
          )}
        </>
      ) : (
        <Box
          sx={{
            borderRadius: 15,
            backgroundColor: 'primaryBackground',
          }}
          p="large"
        >
          <Typography.Text sx={{ fontSize: 24, lineHeight: '32px' }}>
            Search thousands of roles from companies across the globe
          </Typography.Text>

          <JobSearchForm
            location={location}
            control={control}
            setValue={setValue}
          />
          <Button
            text="Find jobs"
            variant="filled"
            intent="primary"
            type="submit"
          />
        </Box>
      )}
    </FormWrapper>
  );
};

export default JobSearch;
