import { Box, Grid, Typography } from '@hero-design/react';

import LinkButton from '@shared/LinkButton';

import BannerImage from './shared/BannerImage';
import BannerTitle from './shared/BannerTitle';

const ApplyEHJobs = () => (
  <Grid>
    <Grid.Row gutter={['medium', 'medium']} sx={{ placeContent: 'center' }}>
      <Grid.Col span={[24, 20, 12, 12, 12]}>
        <BannerImage
          src="/assets/images/apply-eh-jobs-in-second.webp"
          alt="apply eh jobs"
        />
      </Grid.Col>

      <Grid.Col span={[24, 24, 12, 12, 12]} sx={{ alignSelf: 'center' }}>
        <Box pl="xlarge" pr="xlarge">
          <BannerTitle>Cut out 90% of the job hunt</BannerTitle>
          <Typography.Text fontSize={16} sx={{ mb: 'xlarge' }}>
            Employment Hero flips the old job hunt on its head and shows your
            profile directly to our network of 300K+ employers worldwide before
            they even advertise their roles. Just set up your profile once, and
            we’ll match you to roles that suit your interests, skills, and
            experience.
          </Typography.Text>
          <LinkButton
            text="Sign Up"
            href="/setup_profile"
            size="large"
            data-test-id="eh-job-sign-up-button"
          />
        </Box>
      </Grid.Col>
    </Grid.Row>
  </Grid>
);

export default ApplyEHJobs;
