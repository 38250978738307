import styled from 'styled-components';

import Image from '@packages/eh-adapter/image';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';

export const CustomImage = styled(Image)<{ maxHeight?: number }>`
  max-width: 100%;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '440px')};
  margin-left: ${themeGet('space.large')}px;
  width: initial !important;

  ${mediaMaxQueries.md} {
    margin-left: 0px;
  }

  ${mediaMaxQueries.sm} {
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding-left: ${themeGet('space.large')}px;
  padding-right: ${themeGet('space.large')}px;
  text-align: center;

  ${mediaMaxQueries.md} {
    padding-right: 0px;
    padding-left: 0px;
  }

  ${mediaMaxQueries.sm} {
    padding-right: ${themeGet('space.large')}px;
    padding-left: ${themeGet('space.large')}px;
  }
`;

const BannerImage = ({
  src,
  alt,
  maxHeight,
}: {
  src: string;
  alt: string;
  maxHeight?: number;
}) => (
  <ImageWrapper>
    <CustomImage src={src} alt={alt} maxHeight={maxHeight} />
  </ImageWrapper>
);

export default BannerImage;
