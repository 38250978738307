import styled from 'styled-components';

import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';

const BannerTitle = styled.div`
  font-size: 44px;
  line-height: 48px;
  font-weight: ${themeGet('fontWeights.semiBold')};
  margin-bottom: ${themeGet('space.medium')}px;

  ${mediaMaxQueries.xl} {
    font-size: ${themeGet('fontSizes.6xlarge')}px;
    line-height: ${themeGet('lineHeights.6xlarge')}px;
  }
`;

export default BannerTitle;
