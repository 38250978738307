import { Box, Grid, SxObject, Typography } from '@hero-design/react';

import useWindowSize from '@shared/hooks/useWindowSize';

import BannerImage from './shared/BannerImage';
import BannerTitle from './shared/BannerTitle';

const FindJobsBeforeAdvertised = () => {
  const { isSmallScreen } = useWindowSize();

  const generateBackgroundStyles = (showBackground: boolean): SxObject => {
    if (showBackground) {
      return {
        backgroundColor: 'primaryBackground',
        borderRadius: 'large',
      };
    }

    return {};
  };

  return (
    <Box
      mt={isSmallScreen ? undefined : 'xxxlarge'}
      mb={isSmallScreen ? undefined : 'xxxxlarge'}
    >
      <Grid sx={{ mb: 'medium', ml: 'medium', mr: 'medium' }}>
        <Grid.Row
          gutter={['medium', 'medium']}
          sx={{
            placeContent: 'center',
            maxHeight: isSmallScreen ? 'initial' : 440,
            backgroundPosition: 'top right',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '50%',
            flexDirection: isSmallScreen ? 'column-reverse' : 'initial',
            ...generateBackgroundStyles(!isSmallScreen),
          }}
        >
          <Grid.Col
            span={[24, 24, 12, 12, 12]}
            sx={{
              alignSelf: 'center',
              ...generateBackgroundStyles(isSmallScreen),
            }}
          >
            <Box p="xlarge">
              <BannerTitle>Find jobs before they’re advertised</BannerTitle>
              <Typography.Text fontSize={16}>
                Employment Hero shows your profile directly to top employers
                before they even advertise their roles. If you match with an
                opportunity, we get your profile to the front of the job queue
                so that employers can find great talent without paying for a job
                ad! Win-win.
              </Typography.Text>
            </Box>
          </Grid.Col>

          <Grid.Col span={[24, 20, 12, 12, 12]} sx={{ alignSelf: 'center' }}>
            <BannerImage
              src="/assets/images/find-jobs-before-advertised.webp"
              alt="setup eh jobs"
              maxHeight={530}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Box>
  );
};

export default FindJobsBeforeAdvertised;
