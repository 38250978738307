import React, { useEffect } from 'react';
import { NextPage } from 'next';
import { useSession } from 'next-auth/react';

import {
  TRACK_ATS_EH_JOB_BOARD_VISIT,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import ApplyEHJobs from './ApplyEHJobs';
import FindJobsBeforeAdvertised from './FindJobsBeforeAdvertised';
import Header from './Header';
import RebrandingWrapper from './RebrandingWrapper';
import SetupEHJobsBanner from './SetupEHJobsBanner';

const LandingPage: NextPage = () => {
  const { track } = useMixpanelTracking();
  const { status } = useSession();

  useEffect(() => {
    if (status === 'authenticated') {
      track(TRACK_ATS_EH_JOB_BOARD_VISIT, {
        'Is Logged In': status === 'authenticated',
        landing_page_version: 2,
      });
    }
  }, [status, track]);

  return (
    <RebrandingWrapper>
      <Header />
      <SetupEHJobsBanner />
      <FindJobsBeforeAdvertised />
      <ApplyEHJobs />
    </RebrandingWrapper>
  );
};

export default LandingPage;
