import { Box, Grid, Typography } from '@hero-design/react';

import BannerImage from './shared/BannerImage';
import BannerTitle from './shared/BannerTitle';

const SetupEHJobsBanner = () => (
  <Grid>
    <Grid.Row gutter={['medium', 'medium']} sx={{ placeContent: 'center' }}>
      <Grid.Col span={[24, 20, 12, 12, 12]}>
        <BannerImage
          src="/assets/images/setup-eh-jobs-image.webp"
          alt="setup eh jobs"
        />
      </Grid.Col>

      <Grid.Col span={[24, 24, 12, 12, 12]} sx={{ alignSelf: 'center' }}>
        <Box pl="xlarge" pr="xlarge">
          <BannerTitle>The last job application you’ll ever do</BannerTitle>
          <Typography.Text fontSize={16}>
            Set up your Employment Hero jobs profile once, and get job
            opportunities delivered to you for life. Forget scrolling through
            job boards and manually filling out applications.
          </Typography.Text>
        </Box>
      </Grid.Col>
    </Grid.Row>
  </Grid>
);

export default SetupEHJobsBanner;
